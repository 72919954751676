* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat/Montserrat-VariableFont_wght.ttf') format('truetype');
  /* Add other font weights/styles here if you have them */
}

body {
  font-family: "Montserrat", sans-serif !important;
  background-color: #0b0b0b;
  overflow-x: hidden !important;
  scroll-behavior: smooth !important;
}

@media screen and (max-width: 500px) {
  .mt-sm-3 {
    margin-top: 30px;
  }
}

.text-gray {
  color: #747474;
}

.primary-text-black {
  color: #000;
}

.primary-text-red {
  color: red;
}

.primary-text-white {
  color: #fff;
}

.offcanvas.offcanvas-end {
  width: 100vw;
  min-height: 100vh;
  background-color: #000;
}

pre {
  font-family: "Montserrat", sans-serif;
}

@media (min-width: 1200px) {
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    /* flex-grow: 0; */
    padding: 0;
    overflow-y: visible;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }

  .offcanvas.offcanvas-end {
    width: 100vw;
    min-height: auto;
    background-color: #000;
  }
}

@media screen and (max-width: 600px) {
  #offcanvasNavbar {
    background-image: url(./assets/img/pattern\ \(1\).png);
  }

  #offcanvasNavbar .offcanvas-header,
  #offcanvasNavbar .offcanvas-body div.d-xl-none {
    background-color: #000;
  }
}

#Navbar-Main {
  background-color: transparent !important;
  margin: 10px 0;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  /* display: none; */
}

#Navbar-Main .nav {
  border-radius: 16px;
  background: #181818;
  padding: 8px 14px;
  align-items: center;
}

#Navbar-Main .nav-rounded-btn {
  color: #909090;
  background-color: transparent;
  border-radius: 16px;
  display: inline-flex;
  padding: 16px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  transition: all 0.3s;

  color: #909090;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  /* 14.4px */
  letter-spacing: -0.36px;
  text-transform: uppercase;
}

#Navbar-Main .nav-rounded-btn:hover {
  color: #fff;
}

#Navbar-Main .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #0b0b0b;
  background-color: #fff;
  border-radius: 16px;
  display: inline-flex;
  padding: 16px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  transition: all 0.3s;
  color: #0B0B0B;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  /* 14.4px */
  letter-spacing: -0.36px;
  text-transform: uppercase;
}

#pillNav2 li.active,
#pillNav1 li.active {
  background-color: #fff;
  padding: 10px 0px;
  border-radius: 16px;
}

#pillNav1 li.active {
  padding: 10px 10px !important;
}

#pillNav2 li.active .nav-link,
#pillNav1 li.active .nav-link {
  color: #000 !important;
  font-weight: 600;
}

/* .offcanvas-header {
  background-color: #121212;
} */

.offcanvas-body {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

@media screen and (max-width: 600px) {
  .offcanvas-body .socials {
    /* padding-bottom: 1000px !important; */
    margin-bottom: 60px;
  }

  .mobile-navbar .nav-link {
    padding: 15px 0px;
  }
}

#offcanvas_bg {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  /* z-index: -1; */
}

.mobile-navbar {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  z-index: 1;
}

.mobile-navbar .nav-link {
  color: #909090;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 90%;
  /* 18px */
  letter-spacing: -0.6px;
  transition: all 0.3s;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: #fff;
}

.desktop_navbar .nav-link {
  color: #909090;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  /* 14.4px */
  letter-spacing: -0.36px;
  text-transform: uppercase;
  transition: all 0.3s;
}

.mobile-navbar .nav-link:hover,
.desktop_navbar .nav-link:hover {
  color: #fff;
}

.offcanvas .footer {
  /* background-color: #121212; */
  width: 100%;
  height: auto;
  z-index: 1;
}

.footer .socials p {
  color: #747474;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  /* 19.2px */
  letter-spacing: -0.48px;
  text-transform: uppercase;
}

.footer .socials ul {
  list-style-type: none;
  display: flex;
  align-items: center;
  padding: 0;
  gap: 10px;
}

.footer .socials li {
  border: 1px solid #fff;
  display: flex;
  padding: 8px;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

h1 {
  font-family: Montserrat;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  color: #fff;
  line-height: 90%;
  letter-spacing: -5.76px;
}

@media screen and (width: 768px) {
  h1 {
    margin-left: 2rem;
  }
}

@media screen and (min-width: 992px) {
  h1 {
    font-size: 64px;
  }
}

@media screen and (max-width: 600px) {
  h1 {
    font-size: 34px;
    letter-spacing: -1.25px;
  }


  .footer .socials li {
    margin: 0px 5px;
  }
}

h1 .tag-contain {
  position: relative;
}

h1 .tag-contain .tag {
  color: #fff;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  text-transform: capitalize;
  position: absolute;
  left: -32px;
  top: 14px;
  line-height: initial;
  letter-spacing: initial;
}

/* primary-button */

/* custom button */
button:focus {
  /* Provide a fallback style for browsers
     that don't support :focus-visible */
  outline: none;
}

a:focus:not(:focus-visible),
button:focus:not(:focus-visible) {
  /* Remove the focus indicator on mouse-focus for browsers
     that do support :focus-visible */
}

a:focus-visible,
button:focus-visible {
  /* Draw a very noticeable focus style for
     keyboard-focus on browsers that do support
     :focus-visible */
  outline: 2px solid #443ffc;
  outline-offset: 3px;
}

.button {
  pointer-events: auto;
  cursor: pointer;
  border: none;
  position: relative;
  display: inline-block;
}

.button::before,
.button::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 16px;
}

.button-primary {
  color: #fff;
  background: none;
  padding: 0;
  border-radius: 16px;
}

.button-primary .button__bg {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #e7e7e7;
  border-radius: 16px;
  overflow: hidden;
  transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
}

.button-primary:hover .button__bg {
  transform: scale3d(1, 1, 1);
  border: 0.5px solid #fff;
  border-radius: 16px;
}

.button-primary .button__bg::before,
.button-primary .button__bg::after {
  content: "";
  position: absolute;
  background: #000;
  border-radius: 16px;
}

.button-primary .button__bg::before {
  width: 110%;
  height: 0;
  padding-bottom: 110%;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  transform: translate3d(-50%, -50%, 0) scale3d(0, 0, 1);
  border-radius: 16px;
}

.button-primary:hover .button__bg::before {
  transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
  transform: translate3d(-50%, -50%, 0) scale3d(1, 1, 1);
}

.button-primary .button__bg::after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s;
  border-radius: 16px;
}

.button-primary:hover .button__bg::after {
  opacity: 1;
  transition-duration: 0.01s;
  transition-delay: 0.3s;
  border-radius: 16px;
}

.button-primary span {
  display: block;
  position: relative;
  padding: 12px 17px;
  mix-blend-mode: difference;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 90%;
  /* 12.6px */
  letter-spacing: -0.28px;
  text-transform: uppercase;
}

.button-primary span.public_key {
  padding: 15px 17px;
}

.button-primary span:hover {
  color: #fff;
}

/* secondary button */
.button-secondary {
  background-color: #0b0b0b;
  padding: 12px 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  gap: 10px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 16px;

  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 90%;
  /* 12.6px */
  letter-spacing: -0.28px;
  text-transform: uppercase;

  transition: all 0.4s cubic-bezier(0.1, 0, 0.3, 1);
}

.button-secondary:hover {
  border: 1px solid rgba(255, 255, 255);
  color: #fff;
}

@media screen and (max-width: 1200px) {
  .button-primary span {
    padding: 10px 14px;
    font-size: 12px;
  }

  .button-secondary {
    padding: 10px 14px;
    font-size: 10px;
  }
}

button.login-btn a {
  text-decoration: none;
  color: #fff;
}

.tooltiptext {
  margin-top: 10px;
  visibility: visible;
  width: 200px;
  background-color: #fff;
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltiptext::after {
  content: "";
  position: absolute;
  top: -17%;
  left: 20%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #fff transparent;
}

.marquee {
  width: fit-content !important;
  min-width: fit-content !important;
}

.loader {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: #000;
}

.loader video {
  margin: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  /* background-color: rgba(0, 0, 0, 0.8); */
  width: 100%;
  height: 26%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* cursor: none; */
  z-index: -1;
}

.swipable {
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}

.swipable #loyalty,
.swipable #home,
.swipable #nft,
.swipable #discount,
.swipable #interoperability,
.swipable #payments,
.swipable #merchants,
.swipable #faqs,
.swipable #contact,
.swipable #team {
  min-height: 100vh;
  scroll-snap-align: start;

}

.fp-warning,
.fp-watermark {
  display: none !important;
  z-index: -1;
  opacity: 0;
}

.ReactModal__Overlay {
  background-color: #1b1a1a96 !important;
  z-index: 11111111111 !important;
}

.ReactModal__Overlay .ReactModal__Content {
  background: #0B0B0B url(./assets/img/pattern\ \(1\).png) !important;
  background-size: contain !important;
  border: none !important;
  max-width: 500px;
}

@media screen and (max-width: 600px) {
  .ReactModal__Overlay .ReactModal__Content {
    max-height: 100% !important;
  }
}

/* Hide default video controls */
video::-webkit-media-controls {
  display: none !important;
}

/* Hide play button overlay (for some browsers) */
video::-webkit-media-controls-start-playback-button {
  display: none !important;
}